import React from 'react'
import Index from "../views/Sellers"

const Sellers = () => {
    return (
        <div>
            <Index />
        </div>
    )
}

export default Sellers
