import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {GoPrimitiveDot} from "react-icons/go";

const StrugglingSellers = () => {
  return (
      <div className="max-w-6xl mx-auto py-14">
        <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-2 mb-20">
          <div className="rounded-lg shadow-xl">
            <StaticImage
              src="../../images/Group 17 (copy).png"
              alt="stuggle"
              className="rounded-2xl md:max-w-md object-cover stuugleImg"
              placeholder="blurred"
              layout="fixed" height={500} width={500}
            />
          </div>
          <div className=" md:max-w-2xl">
            <div className="pl-20">
              <div className="pb-16 w-fit text-dpurple">
                <h2 className="text-4xl text-bblue">
                  Si tienes estos síntomas...</h2>
                <p className="text-lg justify">da igual que el proyecto esté empezando como un side-project o que sea una startup formalizada, los problemas suelen ser los mismos.</p>
              </div>
              <Text iconColor="#ee2163">No le puedes <p className="nexabold inline">dedicar el tiempo</p> que necesita.</Text>
              <Text iconColor="#ee2163">No consigues <p className="nexabold inline">generar ingresos</p> suficientes.</Text>
              <Text iconColor="#ee2163"><p className="nexabold inline">Hace falta otro perfil</p> para llevarlo al siguiente nivel de madurez.</Text>
              <Text iconColor="#ee2163">Simplemente no sabes <p className="nexabold inline">como vender tu proyecto</p></Text>
              <div className="mx-auto justify-center flex w-6/12 pt-5">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="pink-button w-auto">
                  Necesito ayuda 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default StrugglingSellers;

const Text = ({children, iconColor}) => {
  return (
    <div className="flex pb-3">
      <span>
        <GoPrimitiveDot className="w-7 h-7" style={{color: iconColor}} />
      </span>
      <span className="text-lg nexalight pl-1.5">
        {children}
      </span>
    </div>
  );
};
