import React from "react";
import { Layout } from "@startuponsale/gatsby-shared-ui";
import Header from "./Header";
import StrugglingSellers from "./StrugglingSellers";
import CallToAction from "../../components/CallToAction";
import SellStartup3Steps from "../../components/SellStartup3Steps";
import Services from "../../components/Services"

const Index = () => {
  return (
    <div>
      <Layout>
        <Header />
        <SellStartup3Steps/>
        <StrugglingSellers />
        <Services />
        <CallToAction title="Publica tu proyecto en nuestro marketplace y forma parte de primera la comunidad hispana de compra/venta de negocios." 
                      action="Darme de alta" 
                      link={process.env.APP_URL + "/registro/nuevo"}/>
      </Layout>
    </div>
  );
};

export default Index;
