import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
  return (
    <div className="w-full bg-lblue  ">
      <div className="mx-auto pt-10">
        <div className="flex text-center items-center">
          <div className="md:w-1/2 md:block hidden ">
            <div className="w-full">
              <StaticImage src="../../images/sellerbg.png" alt="" className=" w-full h-auto" />
            </div>
          </div>
          <div className="md:w-1/2 md:max-w-xl md:pl-6 text-center ">
            <h1 className="text-6xl mx-auto pb-16">Vende tu proyecto en tiempo récord</h1>
            <p className=" text-lg text-dpurple pb-16">
            Tanto si tienes un proyecto como una startup digital que necesitas vender, StartupOnSale es tu solución. Lista tus detalles, aparece en nuestro listado y empieza a recibir ofertas de compradores interesados.
            </p>
            <div className="flex mb-10 md:mb-0 mx-auto w-fit">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="pink-button w-auto text-xl mx-5">Vender mi proyecto</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
